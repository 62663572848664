import React, { FC } from "react";
import Header from "~/components/Header";
import HonorSystem from "~/components/HonorSystem";
import Footer from "~/components/Footer/index";
import MyStory from "~/components/MyStory";
import AboutOurHoney from "~/components/AboutOurHoney";
import FeaturedColab from "~/components/FeaturedColab";
import Hero from '~/components/Hero';

const Home: FC = () => {
  return (
    <>
      <Header />
      <Hero />
      <HonorSystem />
      <AboutOurHoney />
      <FeaturedColab/> 
      <MyStory /> 
      <Footer />
    </>
  );
};

export default Home;

import React, { FC } from "react"
import styled from "@emotion/styled";
import { colors, fonts, SpaceScale, breakpoints} from "~/theme";

const Container = styled.footer`
  color: ${colors.wine};
  background-color: ${colors.white};
  font-family: ${fonts.sans};
  font-size: 20px;
  padding: ${SpaceScale(5)} ${SpaceScale(3)};
  font-weight: 400;
  
  p {
    margin: 0 0 5px 0;
  }

  a {
    text-decoration: none;
  }
  
  ${breakpoints.tablet} {
    font-size: 36px;
    padding: ${SpaceScale(6)} ${SpaceScale(8)};
  }
 
`;

const Link = styled.a`
  color: ${colors.pink}
`;

const Arrow = styled.span`
  margin-right: ${SpaceScale(3.5)};

  @media only screen and (max-width: 900px) {
    margin-right: ${SpaceScale(1)}
  }
`;

const Footer: FC = () => {
  return (
  <Container>
    <p><Arrow>&rarr; </Arrow>Follow along, <Link href="https://www.instagram.com/honor.honey/">@honor.honey</Link></p>
    <p><Arrow>&rarr; </Arrow>Say hello, <Link href="mailto:hello@honorhoney.com">hello@honorhoney.com</Link></p>
  </Container>
  )
};

export default Footer;
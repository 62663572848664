import React, { FC } from "react";
import styled from "@emotion/styled";
import Button from "~/components/Button";
import { colors, SpaceScale, breakpoints, FontScale } from "~/theme";

const Background = styled.header`
position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.pink};
  padding: 0 ${SpaceScale(4)};
  height: ${SpaceScale(7.5)};

  ${breakpoints.tablet} {
    padding: 0 ${SpaceScale(6.5)};
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet} {
    flex-direction: row;
  }
`;
const HonorHoney = styled.h1`
  color: white;
  font-size: ${FontScale(5)};
  font-weight: 800;
  margin: 0;

  ${breakpoints.mobile} {
    display: none;
  }
`;
const Partner = styled.h2`
  color: white;
  font-size: ${FontScale(5)};
  font-weight: 400;
  margin: 0;

  ${breakpoints.tablet} {
    margin: 0 0 0 8px;
  }
`;



const Header: FC = () => {
  return (
      <Background>
        <Text>
          <HonorHoney>Honor Honey honors</HonorHoney>
          <Partner>Lakeview Pantry</Partner>
        </Text>
        <Button nature="ghost" href="https://secure.qgiv.com/event/lakpanp2p/account/1019129/">
          Donate
        </Button>
      </Background>
  );
};

export default Header;

import { Ratio, Scale } from "natural-scale";


export const DEFAULT_UNIT = "rem";
export const FontScale = Scale({
  interval: Ratio.AUGMENTED_FOURTH,
  unit: DEFAULT_UNIT,
});

export const SpaceScale = Scale({
  interval: Ratio.GOLDEN_RATIO,
  unit: DEFAULT_UNIT,
});

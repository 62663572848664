import * as React from "react";
import { Global } from "@emotion/core";
import globalStyles from "./globalStyles";
import { Home } from "./views";

const App: React.FunctionComponent = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <Home />
    </>
  );
};

export default App;

import React, { FC } from "react";
import styled from "@emotion/styled";
import { colors, FontScale, fonts, breakpoints } from "~/theme";
import Wordmark from "~/svg/Mark.svg";
import Div100vh from "react-div-100vh";

const Container = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-color: ${colors.wine};
`;

const SubTitle = styled.h2`
  color: ${colors.pink};
  margin: 0;
  font-size: ${FontScale(5.35)};
  font-family: ${fonts.serif};
  font-weight: 400;
`;

const MarkContainer = styled.div`
  width: 245px;

  > svg {
    width: 100%;
  }

  ${breakpoints.tablet} {
    width: 395px;
  }
`;

const Hero: FC = () => {
  return (
    <Container>
      <MarkContainer>
        <div>
          <Wordmark />
        </div>
      </MarkContainer>
      <SubTitle>Sweetness for good</SubTitle>
    </Container>
  );
};

export default Hero;

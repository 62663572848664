import React, { FC } from "react";
import styled from "@emotion/styled";
import { colors, fonts, FontScale, SpaceScale } from "~/theme";

const FlexContainer = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column;
    height: inherit;
  }
`;


interface PColor {
  background: string
  color: string | undefined
}

const Container = styled.div<PColor>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  padding: 100px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};

  ul {
    list-style-type: none;
    font-size: 47px;
    font-weight: 600;
    font-family: ${fonts.serif};

    @media (max-width: 900px) {
      margin: 0 auto;
    }
  }

  li:first-of-type {
    margin-bottom: ${SpaceScale(5)};
  }

  @media (max-width: 1150px) {
    font-size: ${FontScale(6)};
    min-height: 550px;
    padding: ${SpaceScale(5)};
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Copy = styled.div`
  /* width: 75%; */
  font-size: 26px;
  font-family: ${fonts.sans};

  @media (max-width: 1150px) {
    font-size: ${FontScale(5)};
  }
`;

// @ts-ignore
const AboutOurHoney: FC = () => {
  return (
    <FlexContainer>
      <Container background={colors.pink} color={colors.white}>
        <ul>
          <li>+</li>
          <li>Unfiltered.</li>
          <li>Raw.</li>
          <li>Just as nature intended.</li>
        </ul>
      </Container>
      <Container background={colors.white} color={colors.wine}>
        <Copy>
          <p>
            Our honey is hand-harvested from rooftop hives on Magnolia Ave in
            Chicago’s Lincoln Park neighborhood. It’s unfiltered and raw—just as
            nature intended. It’s probably organic, too. But we can’t say that
            officially because we can’t be 100% sure of everything our bee
            friends eat.
          </p>
          <p>
            You may notice slight color and taste variations between jars.
            Seasonal shifts in plant life alter the food available to our
            honeybees and the result is delicious diversity.
          </p>
        </Copy>
      </Container>
    </FlexContainer>
  );
};

export default AboutOurHoney;
